<template>
  <v-card
    outlined
    flat
    height="100vh"
    class="chatbox-main-section overflow-hidden"
    v-bind="$attrs"
  >
    <template v-if="hasToolbar">
      <v-toolbar v-if="selected_conversation" class="message-chatbox" flat>
        <template v-if="selected_conversation.type === 'group'">
          <div class="d-flex align-center">
            <div class="d-block">
              <Avatars
                :items="selected_conversation.participants"
                :count="3"
              ></Avatars>
            </div>
            <div class="ml-2">
              <v-toolbar-title class="subtitle-2 primary--text">
                {{ selected_conversation.name | ucwords }}
              </v-toolbar-title>
              <v-toolbar-title class="fw-500 fs-12 secondary--text">
                Group Conversation
              </v-toolbar-title>
            </div>
          </div>
        </template>
        <template v-else-if="selected_conversation.target">
          <div class="d-flex align-center">
            <div class="d-block">
              <Avatar :user="selected_conversation.target" icon-only />
            </div>
            <div class="ml-2">
              <v-toolbar-title class="subtitle-2 primary--text">
                {{ selected_conversation.target.fullname | ucwords }}
              </v-toolbar-title>
              <v-toolbar-title class="fw-500 fs-12 secondary--text">
                {{ selected_conversation.target.job_title | ucwords }}
              </v-toolbar-title>
            </div>
          </div>
        </template>
        <v-spacer />
        <HelpToolTip
          icon-class="mx-1"
          text="Manage conversation"
          icon-color="primary"
          v-if="selected_conversation.type === 'group' && isGroupCreator"
          @click:icon="open_edit_group_dialog = true"
          icon="mdi-message-cog"
        ></HelpToolTip>
        <HelpToolTip
          icon-class="mx-1"
          text="Leave conversation"
          icon-color="primary"
          v-if="selected_conversation.type === 'group'"
          @click:icon="handleLeaveConversation"
          icon="mdi-message-minus"
        ></HelpToolTip>
      </v-toolbar>
      <v-toolbar flat v-else />
    </template>
    <v-sheet
      v-if="selected_conversation"
      class="overflow-y-auto fill-height px-2"
      color="white"
      :style="
        hasToolbar
          ? `height: calc(100vh - 150px)`
          : `height: calc(100vh - 80px)`
      "
      @scroll="onScrollTop"
      id="chat-box-wrapper"
    >
      <v-btn
        x-small
        :loading="loading_more_messages"
        text
        v-if="selected_conversation.next_page_url && !loading_more_messages"
        depressed
        class="
          mx-auto
          my-1
          text-none
          d-flex
          align-self-center
          primary--text
          fw-700
        "
        @click="fetchConversationMoreMessages(selected_conversation.id)"
        outlined
      >
        Load previous message
      </v-btn>
      <PageLoader
        class="ma-2"
        height="450"
        v-if="loading_messages"
      ></PageLoader>
      <Empty
        min-height="450"
        headline="No message yet"
        v-if="selected_conversation.messages.length === 0 && !loading_messages"
      ></Empty>
      <template
        v-else-if="
          selected_conversation.messages.length > 0 && !loading_messages
        "
      >
        <Message
          v-for="(message, index) in conversationMessages"
          :message="message"
          :disabled="message.deleted_at"
          :key="message.id"
          :before-message="index > 1 ? conversationMessages[index - 1] : null"
          :allow-delete="allowMessageDelete"
          @delete="deleteMessage(message)"
          @view-attachment="viewAttachment"
        />
      </template>
      <span ref="bottom-messages"></span>
    </v-sheet>
    <v-card-actions id="chat-input-wrapper" v-if="selected_conversation">
      <ChatField
        :small="!mdAndUp"
        :mentionables="mentionableList"
        @typing="handleTyping"
        @send-message="sendConversationMessage"
      ></ChatField>
    </v-card-actions>
    <Empty min-height="100%" v-else headline="No active conversation"></Empty>
    <AttachmentSoloViewer
      v-model="attachment_viewer_dialog"
      :attachment="activeAttachment"
    ></AttachmentSoloViewer>
    <GroupChatDialog
      v-model="open_edit_group_dialog"
      :user-list="canBeAddedToChat"
      :conversation="selected_conversation"
      @update="handleUpdateConversation"
    ></GroupChatDialog>
  </v-card>
</template>

<script>
import ChatField from '@/common/ChatBox/ChatField.vue'
import Message from '../Message/MessageV3.vue'
import DeleteDialog from '@/common/DeleteDialog.vue'
import AttachmentSoloViewer from '@/common/Viewer/AttachmentSoloViewer.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import GroupChatDialog from '@/modules/Chat/components/GroupChatDialog/GroupChatDialog.vue'
export default {
  inheritAttrs: false,
  name: 'ChatBox',
  components: {
    ChatField,
    Message,
    GroupChatDialog,
    AttachmentSoloViewer,
    DeleteDialog
  },
  data: () => ({
    open_edit_group_dialog: false,
    attachment_viewer_dialog: false,
    activeAttachment: null
  }),
  props: {
    messageHeight: { type: Number, default: 500 },
    hasToolbar: { type: Boolean, default: true },
    allowMessageDelete: { type: Boolean, default: true }
  },
  computed: {
    ...mapGetters(['user', 'users']),
    ...mapGetters('conversations', [
      'selected_conversation',
      'fetching',
      'loading_messages',
      'loading_more_messages'
    ]),
    mentionableList() {
      if (!this.selected_conversation) return []
      return this.selected_conversation.participants.filter(
        (i) => i.id !== this.user.id
      )
    },
    conversationMessages() {
      if (!this.selected_conversation) return []
      return this.selected_conversation.messages
    },
    canBeAddedToChat() {
      return _.cloneDeep(this.users).filter((i) => {
        if (this.user.id === i.id) return false
        if (this.user.is_admin || this.user.is_manager) return true
        if (this.user.client && i.is_client) return false
        if (
          !this.user.is_owner &&
          !this.user.is_admin &&
          !this.user.is_manager &&
          i.is_client
        )
          return false
        else return true
      })
    },
    isGroupCreator() {
      if (!this.selected_conversation) return false
      return (
        this.selected_conversation.type === 'group' &&
        this.selected_conversation.props.group_creator &&
        this.user.id == this.selected_conversation.props.group_creator
      )
    },
    no_active_convo_height() {
      return this.messages.length === 0
        ? this.messageHeight + this.chat_input_height
        : 0
    },
    chat_input_height() {
      return document.getElementById('chat-input-wrapper')
        ? document.getElementById('chat-input-wrapper').offsetHeight
        : 0
    }
  },
  watch: {
    'selected_conversation.id': {
      handler: function (new_val, old_val) {
        if (new_val && new_val !== old_val) {
          this.$store
            .dispatch('conversations/fetchConversationMessages', new_val)
            .then(() => {
              this.scrollToRef('bottom-messages')
            })
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions('conversations', [
      'sendMessage',
      'setActiveConversation',
      'fetchConversationMoreMessages',
      'deleteConversationMessage',
      'updateConversation',
      'leaveConversation'
    ]),
    onScrollTop(event) {
      if (!this.selected_conversation.next_page_url) return
      if (event.target.scrollTop === 0 && !this.loading_more_messages) {
        this.fetchConversationMoreMessages(this.selected_conversation.id)
      }
    },
    sendConversationMessage(payload) {
      let formData = new FormData()
      formData.append('message', payload.message)
      if (payload.file) {
        formData.append('file', payload.file)
      }
      this.sendMessage({
        id: this.selected_conversation.id,
        payload: formData,
        cb: () => {
          this.scrollToRef('bottom-messages')
        }
      }).then(() => {
        this.$event.$emit('btnsending_off', true)
      })
    },
    viewAttachment(attachment) {
      this.activeAttachment = attachment
      this.attachment_viewer_dialog = true
    },
    deleteMessage(message) {
      this.appConfirmation('Delete this message? This cant be undone.', () => {
        this.deleteConversationMessage({
          conversation_id: this.selected_conversation.id,
          message_id: message.id,
          cb: () => {
            this.appSnackbar('Message deleted')
          }
        })
      })
    },
    handleUpdateConversation(payload) {
      this.updateConversation({
        conversation_id: this.selected_conversation.id,
        payload: payload,
        cb: (conversation) => {
          this.open_edit_group_dialog = false
          this.appSnackbar('Conversation updated')
        }
      })
    },
    handleTyping() {
      this.scrollToRef('bottom-messages')
      // console.log('Typing...', {
      //   conversation_id: this.selected_conversation.id,
      //   user_id: this.user.id
      // })
    },
    handleLeaveConversation() {
      this.appConfirmation(
        'Are you sure you want to leave this conversation?',
        () => {
          this.leaveConversation({
            conversation_id: this.selected_conversation.id,
            cb: () => {
              this.appSnackbar('You have left the conversation')
              this.setActiveConversation(null)
            }
          })
        }
      )
    }
  }
}
</script>

<style scoped lang="scss">
.chatbox-main-section {
  border-radius: 0 15px 15px 0;
  .message-chatbox {
    border-bottom: 1px solid rgb(199 204 214);
    .v-toolbar__content {
      display: block !important;
    }
    .user-active {
      font-size: 12px;
      color: #223a6b !important;
    }
  }
  #chat-box-wrapper {
    position: relative;
    margin-bottom: 80px;
  }

  #chat-input-wrapper {
    background: #ececec;
    position: absolute;
    bottom: 0;
    height: 80px;
    width: 100%;
  }
}
</style>
