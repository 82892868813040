var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"message-wrapper d-flex align-items-center",attrs:{"flat":"","disabled":!!_vm.message.deleted_at}},[(!_vm.mine && !_vm.stillTheSender)?_c('Avatar',{attrs:{"user":_vm.message.sender,"icon-only":""}}):_vm._e(),_c('v-tooltip',{attrs:{"left":_vm.mine,"right":!_vm.mine},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"message-box align-self-center",class:[
            _vm.mine ? 'mine ml-auto mr-3' : 'mr-auto',
            { 'arrow-right': _vm.hasChatArrow && _vm.mine },
            { 'arrow-left': _vm.hasChatArrow && !_vm.mine },
            { 'mr-15': !_vm.hasChatArrow && _vm.mine },
            { 'ml-14': !_vm.hasChatArrow && !_vm.mine },
            { hover: hover }
          ]},'div',attrs,false),on),[(hover && _vm.can_delete && !_vm.message.deleted_at)?_c('div',{staticClass:"action",class:{ 'action-mine': _vm.mine }},[_c('v-btn',{attrs:{"color":"error","small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('delete')}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete ")])],1)],1):_vm._e(),(
              _vm.message.formatted_body &&
              _vm.message.formatted_body != 'undefined' &&
              !_vm.message.deleted_at
            )?_c('div',{staticClass:"subtitle-2 message-text fs-12",domProps:{"innerHTML":_vm._s(_vm.message.formatted_body || '')}}):_vm._e(),(_vm.message.attachment && !_vm.deleted)?_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var hover2 = ref.hover;
return [_c('v-sheet',{attrs:{"color":"transparent","max-width":"300"}},[(hover2)?_c('v-overlay',{attrs:{"absolute":""}},[_c('v-btn',{attrs:{"href":_vm.message.attachment.url,"small":""}},[_vm._v("Download")])],1):_vm._e(),_c('Attachment',{attrs:{"item":_vm.message.attachment}})],1)]}}],null,true)}):_vm._e(),(_vm.message.deleted_at)?_c('i',{staticClass:"grey--text"},[_vm._v("Message removed!")]):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("format")(_vm.message.created_at,'lll')))])]),(_vm.mine && !_vm.stillTheSender)?_c('Avatar',{attrs:{"user":_vm.message.sender,"icon-only":""}}):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }