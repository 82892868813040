<template>
  <v-hover v-slot:default="{ hover }">
    <v-card
      flat
      class="message-wrapper d-flex align-items-center"
      :disabled="!!message.deleted_at"
    >
      <Avatar
        v-if="!mine && !stillTheSender"
        :user="message.sender"
        icon-only
      ></Avatar>
      <v-tooltip :left="mine" :right="!mine">
        <template v-slot:activator="{ on, attrs }">
          <div
            v-bind="attrs"
            v-on="on"
            class="message-box align-self-center"
            :class="[
              mine ? 'mine ml-auto mr-3' : 'mr-auto',
              { 'arrow-right': hasChatArrow && mine },
              { 'arrow-left': hasChatArrow && !mine },
              { 'mr-15': !hasChatArrow && mine },
              { 'ml-14': !hasChatArrow && !mine },
              { hover: hover }
            ]"
          >
            <div
              v-if="hover && can_delete && !message.deleted_at"
              :class="{ 'action-mine': mine }"
              class="action"
            >
              <v-btn color="error" @click.stop="$emit('delete')" small icon>
                <v-icon small>mdi-delete </v-icon>
              </v-btn>
            </div>
            <div
              v-if="
                message.formatted_body &&
                message.formatted_body != 'undefined' &&
                !message.deleted_at
              "
              v-html="message.formatted_body || ''"
              class="subtitle-2 message-text fs-12"
            ></div>
            <v-hover
              v-slot:default="{ hover: hover2 }"
              v-if="message.attachment && !deleted"
            >
              <v-sheet color="transparent" max-width="300">
                <v-overlay v-if="hover2" absolute>
                  <v-btn :href="message.attachment.url" small>Download</v-btn>
                </v-overlay>
                <Attachment :item="message.attachment"></Attachment>
              </v-sheet>
            </v-hover>
            <i v-if="message.deleted_at" class="grey--text">Message removed!</i>
          </div>
        </template>
        <span>{{ message.created_at | format('lll') }}</span>
      </v-tooltip>

      <Avatar
        v-if="mine && !stillTheSender"
        :user="message.sender"
        icon-only
      ></Avatar>
    </v-card>
  </v-hover>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  inheritAttrs: false,
  name: 'Message',
  props: {
    message: Object,
    beforeMessage: Object,
    allowDelete: { type: Boolean, default: true },
    allowEdit: { type: Boolean, default: false }
  },
  computed: {
    ...mapGetters({ viewer: 'user' }),
    hasChatArrow() {
      return !this.stillTheSender
    },
    hasImageAttachment() {
      return this.message.id % 2 === 0
    },
    deleted() {
      return this.message.deleted_at ? true : false
    },
    mine() {
      return this.message.sender.id === this.viewer.id
    },
    stillTheSender() {
      return (
        this.beforeMessage &&
        this.beforeMessage.sender.id === this.message.sender.id
      )
    },
    can_delete() {
      return this.mine && this.allowDelete
    }
  },
  methods: {
    viewAttachment(attachment) {
      this.$emit('view-attachment', attachment)
    }
  }
}
</script>

<style lang="scss" scoped>
.message-box {
  width: auto;
  height: auto;
  min-width: 40px;
  color: #000000;
  padding: 5px 10px;
  position: relative;
  margin: 5px;
  border-radius: 2px;
  background: $gray;

  .action {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 9;
  }
  // &.mine {
  //   &.hover {
  //     color: #ffffff;
  //     background: $darkBlue;
  //   }
  // }
  &.arrow-right:after {
    content: ' ';
    position: absolute;
    right: -5px;
    top: 1px;
    height: 12px;
    border-top: 5px solid transparent;
    border-right: none;
    border-left: 5px solid $gray;
    border-bottom: 5px solid transparent;
  }

  &.arrow-left:after {
    content: ' ';
    position: absolute;
    left: -5px;
    top: 3px;
    height: 12px;
    border-top: 5px solid transparent;
    border-right: 5px solid $gray;
    border-left: none;
    border-bottom: 5px solid transparent;
  }
  .message-text {
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
    max-width: 100%;
  }
}
</style>
<style lang="css">
.profile-link {
  color: green !important;
  cursor: pointer;
}
</style>
